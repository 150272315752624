<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #card>
        <div class="text-center">
          <div>
            <h1>Almost there</h1>
          </div>
          <div>
            <p>Kindly enter the MFA sent to your multi factor authentication</p>
          </div>
        </div>
        <validation-observer ref="loginForm" #default="{invalid}">
          <b-form @submit.prevent="login">
            <!--                username -->
            <b-form-group
                label="Verification Code"
                label-for="mfa"
            >
              <validation-provider
                  #default="{ errors }"
                  name="mfa"
                  vid="mfa"
                  rules="required"
              >
                <div class="exxtra-input">
                  <input id="login-username" v-model="mfa" type="number"
                         placeholder="******"
                  >
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


            <div>
              <b-button
                  class="py-1"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
              >
                Verify
              </b-button>
            </div>
            <div class="text-center">
              <p class="pt-1"> Resend Code ?
                <span>
                        <b-link :to="{ name:'auth-register' }">
                          <span>30s </span>
                        </b-link>
                      </span>
              </p>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </AuthWrapper>
  </b-overlay>
</template>

<script>
// import store from '@/store/index'
import { MUTATE_USER_ABILITIES } from "@/store/config/mutation-keys"
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

import TurtleRentLogo from '@core/layouts/components/Logo.vue';
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import {
  BButton,

  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import { get } from "lodash"
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    AuthWrapper,
    Rectangles,
    BOverlay,

    BLink,
    BFormGroup,
    BFormInput,

    BFormCheckbox,

    BImg,
    BForm,
    BButton,
    // BAlert,
    BottomDesign,
    TurtleRentLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      error: '',
      remember_me: '',
      password: '',
      username: '',
      mfa: "",
      // validation rules
      required
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require('@/assets/images/logo/logo-2.png')
      //   return this.sideImg
      // }
      return this.sideImg
    },
  },
  created() {
    const {
      token,
      session,
      activated
    } = this.$route.query;
    if (token) {
      this.$router.replace({ query: {} });
    }

    if (session && session === "expired") {
      this.$router.replace({ query: {} });
      this.error = "Your account confirmation token has expired. Please login to get a new confirmation token.";
    }

    if (activated) {
      this.$router.replace({ query: {} });
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: 'Your account has been activated successfully.',
        },
      })
    }
  },
  methods: {
    async login() {
      try {
        this.loading = true
        this.error = "";

        const success = await this.$refs.loginForm.validate();
        if (!success) {
          throw new Error("Please check and make sure all fields are valid");
        }

        const response = await this.useJwt()
            .authService
            .login({
              username: this.username,
              password: this.password,
            });

        const {
          access_token,
          user,
          abilities
        } = response.data.data
        this.useJwt()
            .authService
            .setToken(access_token)
        this.$ability.update(abilities)
        this.$store.commit(`auth/${MUTATE_USER_ABILITIES}`, abilities)

        this.$router.replace({
          path: "/",
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${user.first_name || user.last_name}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully logged in as ${user.user_type}.`,
            },
          })
        })
        .catch(() => {});
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

sup {
  font-size: 1rem;
}
</style>
